import { useCallback } from "react";
import { useFarmFromPid } from "state/hooks";
import { stake } from "utils/callHelpers";
import { useMasterchef } from "./useContract";
import { useAccount } from "wagmi";
import { utils } from "ethers";

const useStake = (pid, isNFTPool) => {
  const { address } = useAccount();
  const masterChefContract = useMasterchef();
  const farm = useFarmFromPid(pid);

  const tokenDecimals = farm.isTokenOnly ? farm.token.decimals : 18;
  const handleStake = useCallback(
    async (amount, eoa, isNFTALL) => {
      console.log( isNFTPool);
      const isValidAddress = utils.isAddress(eoa);
      if (!isValidAddress) {
        const custom_urn = eoa;
        const promo_address = '0x0000000000000000000000000000000000000000';
        await stake(
          masterChefContract,
          pid,
          amount,
          custom_urn,
          promo_address,
          tokenDecimals,
          isNFTPool,
          isNFTALL
        );
      } else {
        const custom_urn = '';
        const promo_address = eoa;
        await stake(
          masterChefContract,
          pid,
          amount,
          custom_urn,
          promo_address,
          tokenDecimals,
          isNFTPool,
          isNFTALL
        );
      }

      // const whitelistMerkleTree = StandardMerkleTree.of(
      //   merkleTree.values.map((item) => item.value),
      //   merkleTree.leafEncoding,
      // )
      const proof = [];
      // try {
      //   proof = whitelistMerkleTree.getProof([account])
      // } catch (e) {
      //  console.log('Whitelist check error', e)
      // }
      // await stake(
      //   masterChefContract,
      //   pid,
      //   amount,
      //   tokenDecimals,
      //   isNFTPool,
      //   isNFTALL
      // );
    },
    [address, masterChefContract, pid, tokenDecimals, isNFTPool]
  );

  return { onStake: handleStake };
};

export default useStake;

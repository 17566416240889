import React from 'react';

import Profit from "components/ReferComponents/Profit";
import ReferalStatus from "components/ReferComponents/ReferalStatus";
import ReferralPage from 'components/ReferComponents/ReferralPage';

export default function Refer() {
  return (
    <div className="flex justify-center w-full md:max-w-4xl mt-10">
      <div className="container m-3 flex flex-col gap-3">
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:mt-16 gap-3">
          <Profit />
          <ReferalStatus />
        </div>
        <ReferralPage />
      </div>
    </div>
  );
}

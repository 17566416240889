export default function ReferalStatus() {
  return (
    <div className="flex flex-col snow_effect p-8">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-3">
          <p>Active referals</p>
          <span className="flex gap-4 items-center text-2xl font-[400]">
            <div className="bg-[#3FBB7D] p-[7px] rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12.1605 10.87C12.0605 10.86 11.9405 10.86 11.8305 10.87C9.45055 10.79 7.56055 8.84 7.56055 6.44C7.56055 3.99 9.54055 2 12.0005 2C14.4505 2 16.4405 3.99 16.4405 6.44C16.4305 8.84 14.5405 10.79 12.1605 10.87Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.1607 14.56C4.7407 16.18 4.7407 18.82 7.1607 20.43C9.9107 22.27 14.4207 22.27 17.1707 20.43C19.5907 18.81 19.5907 16.17 17.1707 14.56C14.4307 12.73 9.9207 12.73 7.1607 14.56Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            0
          </span>
        </div>
        <div className="flex flex-col">
          <h3 className="font-semibold">Referral Progress</h3>
          <p className="text-gray-400">5/10 <span className="text-sm">referrals to earn a bonus.</span></p>
        </div>
      </div>
      <div className="border-t border-[#D7E2F0] my-4"></div>
      <div className="flex justify-between">
        <div className="flex flex-col gap-3">
          <p>Sign-ups</p>
          <span className="flex gap-4 items-center text-2xl font-[400]">
            <div className="bg-[#EE9500] p-[7px] rounded-lg">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M23.332 15.97C23.332 19.84 20.202 22.97 16.332 22.97L17.382 21.22" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M1.33203 7.96997C1.33203 4.09997 4.46203 0.969971 8.33203 0.969971L7.28203 2.71997" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6.95117 13.0698H9.76117C10.3812 13.0698 10.8912 13.6298 10.8912 14.1998C10.8912 14.8198 10.3912 15.3298 9.76117 15.3298H6.95117V13.0698Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6.95117 15.3301H10.1712C10.8812 15.3301 11.4612 15.8301 11.4612 16.4601C11.4612 17.0801 10.8812 17.5901 10.1712 17.5901H6.95117V15.3301Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.75195 17.5801V18.7001" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.75195 11.95V13.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.1818 15.33C15.1818 18.74 12.4218 21.4999 9.0118 21.4999C5.6018 21.4999 2.8418 18.74 2.8418 15.33C2.8418 11.92 5.6018 9.15991 9.0118 9.15991C9.1718 9.15991 9.3218 9.16993 9.4918 9.17993C12.5218 9.40993 14.9418 11.8299 15.1718 14.8599C15.1718 15.0099 15.1818 15.16 15.1818 15.33Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M21.8322 8.66998C21.8322 12.08 19.0722 14.84 15.6622 14.84H15.1722C14.9422 11.81 12.5222 9.38997 9.49219 9.15997V8.66998C9.49219 5.25998 12.2522 2.5 15.6622 2.5C19.0722 2.5 21.8322 5.25998 21.8322 8.66998Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            1
          </span>
        </div>
        <div className="flex flex-col gap-3">
          <p>Inactive</p>
          <span className="flex gap-4 items-center text-2xl font-[400]">
            <div className="bg-[#859AB5] p-[7px] rounded-lg">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M2.69513 12.42C3.05513 17.57 7.42513 21.76 12.6551 21.99C16.3451 22.15 19.6451 20.43 21.6251 17.72C22.4451 16.61 22.0051 15.87 20.6351 16.12C19.9651 16.24 19.2751 16.29 18.5551 16.26C13.6651 16.06 9.66513 11.97 9.64513 7.13996C9.63513 5.83996 9.90513 4.60996 10.3951 3.48996C10.9351 2.24996 10.2851 1.65996 9.03513 2.18996C5.07513 3.85996 2.36513 7.84996 2.69513 12.42Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            </div>
            0
          </span>
        </div>
        <div className="flex flex-col gap-3">
          <p>All referrals</p>
          <span className="flex gap-4 items-center text-2xl font-[400]">
            <div className="bg-[#0F75FC] p-[7px] rounded-lg">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18.0001 7.16C17.9401 7.15 17.8701 7.15 17.8101 7.16C16.4301 7.11 15.3301 5.98 15.3301 4.58C15.3301 3.15 16.4801 2 17.9101 2C19.3401 2 20.4901 3.16 20.4901 4.58C20.4801 5.98 19.3801 7.11 18.0001 7.16Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M16.9695 14.4399C18.3395 14.6699 19.8495 14.4299 20.9095 13.7199C22.3195 12.7799 22.3195 11.2399 20.9095 10.2999C19.8395 9.58992 18.3095 9.34991 16.9395 9.58991" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M5.96852 7.16C6.02852 7.15 6.09852 7.15 6.15852 7.16C7.53852 7.11 8.63852 5.98 8.63852 4.58C8.63852 3.15 7.48852 2 6.05852 2C4.62852 2 3.47852 3.16 3.47852 4.58C3.48852 5.98 4.58852 7.11 5.96852 7.16Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M6.99945 14.4399C5.62945 14.6699 4.11945 14.4299 3.05945 13.7199C1.64945 12.7799 1.64945 11.2399 3.05945 10.2999C4.12945 9.58992 5.65945 9.34991 7.02945 9.58991" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12.0001 14.63C11.9401 14.62 11.8701 14.62 11.8101 14.63C10.4301 14.58 9.33008 13.45 9.33008 12.05C9.33008 10.62 10.4801 9.46997 11.9101 9.46997C13.3401 9.46997 14.4901 10.63 14.4901 12.05C14.4801 13.45 13.3801 14.59 12.0001 14.63Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M9.0907 17.7799C7.6807 18.7199 7.6807 20.2599 9.0907 21.1999C10.6907 22.2699 13.3107 22.2699 14.9107 21.1999C16.3207 20.2599 16.3207 18.7199 14.9107 17.7799C13.3207 16.7199 10.6907 16.7199 9.0907 17.7799Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            </div>
            1
          </span>
        </div>
      </div>
    </div>
  );
}

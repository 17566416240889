const contractAddresses = {
  router: "0x165C3410fC91EF562C50559f7d2289fEbed552d9",
  factory: "0x29ea7545def87022badc76323f373ea1e707c523",
  dai: "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
  wpls: "0xa1077a294dde1b09bb078844df40758a5d0f9a27",
  usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  usdcLp: "0x8F6DfB2Fa2f7Ccf9d7106E96207d8B947a89998a",

  snow: "0xdc318Ea55e15Fd480a7c9baEc6d957Cf602b5063",
  bill: "0xc0F1BA2780bBb41363d94859D8EBC26809dcC010",
  snowWplslp: "0xB564f77D0E4F295f4b0E8528c15b7fb432aB1D34",
  billWplslp: "0x1C2f109e263DF5575751A83e071Cd55C7f4Ac096",
  nft: "0xb9520710e38b8D83554962D2e4Fc0D311653D176",
  zap: "0x983389Eee6Bb76419b0176d64E1dB8191213A0B5",
  masterChef: "0x520E7c83B0859FC6915AcC75A7b4d4FD256F97FA",
  stake: "0x50c59f6ac05ed909750f8ab3d6539492b36fe4bc",
  stakeToken: "0x6C99174867C1f41a2DfE0b196A667e6130Cf3C61",
  lib: "0x1c815565415116081Bc5b5DF359F41bdF866735e",
  config: "0xD0B984883A640FeEC527014B7364C0d94061e006",
  snowConfig: "0x790C736Fd431420086a2b4C9505BADAf0676B8B9",
};

export default contractAddresses;

import { useCallback } from "react";
import { register } from "utils/callHelpers";
import { useMasterchef } from "./useContract";
import { useEthersProvider } from "./useEthers";
import { getSnowConfigContract } from "utils/contractHelpers";

// Register a PROMO
export const useRegister = () => {
  const masterChefContract = useMasterchef();

  const handleRegister = useCallback(async (eoa, customURN) => {
    await register(
      masterChefContract,
      eoa,
      customURN
    );
  }, [masterChefContract]);

  return { onRegister: handleRegister };
};

export const usePromotorPromo = () => {
  const provider = useEthersProvider();
  const snowConfigContract = getSnowConfigContract(provider);

  const handlePromotorPromo = useCallback(async (eoa) => {
    const promotorPromo = await snowConfigContract.PROMOTOR_PROMO(eoa);
    if(promotorPromo['customURN'] !== '')
      return promotorPromo['customURN'];
    else return ''
  }, [])
  return { onPromotorPromo: handlePromotorPromo };
};

export const useURNPromo = () => {
  const provider = useEthersProvider();
  const snowConfigContract = getSnowConfigContract(provider);

  const handleURNPromo = useCallback(async (customURN) => {
    const urnPromo = await snowConfigContract.URN_PROMO(customURN);
    if(urnPromo['customURN'] == customURN)
      return urnPromo['promotor'];
    else return ''
  }, [])
  return { onURNPromo: handleURNPromo };
};

export default useRegister;

export default function Profit() {
  return (
    <div className="flex-1 p-8 rounded-md snow_effect">
      <div className="flex items-left flex-col">
        <span className="text-[16px] flex items-center gap-2">
          Profit
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z" stroke="#859AB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 6V9.75" stroke="#859AB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.99609 12H9.00283" stroke="#859AB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
        <div className="my-3 flex justify-between items-end">
          <span className="sm:text-4xl text-3xl">0 SNOW</span>
          <span className="sm:text-2xl text-xl text-gray-400">0.00 USD</span>
        </div>
      </div>
      <button
        className="main_btn mt-8 hover:bg-symbolHover disabled:opacity-50 disabled:hover:scale-100  w-full rounded-lg transition ease-in-out p-[8px] bg-secondary-700"
      >
        Request withdrawal
      </button>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useRegister, usePromotorPromo } from 'hooks/useRegister';
import Loading from 'components/Loading';
import { didUserReject } from 'utils/customHelpers';
import { notify } from 'utils/toastHelper';
import { useAccount } from 'wagmi';

export default function ReferralPage() {
  const [referralLink, setReferralLink] = useState("");
  const [customURN, setCustomURN] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [clipboard, setClipboard] = useState(false);
  const { address } = useAccount();

  const handleChange = (e) => {
    setCustomURN(e.target.value)
  };

  const [pendingRegisterTx, setRegisterPendingTx] = useState(false);
  const { onRegister } = useRegister();
  const { onPromotorPromo } = usePromotorPromo();
  const isButtonDisabled = !customURN.trim() || pendingRegisterTx;
  useEffect(() => {
    async function fetchPromo() {
      setLoading(true);
      const eoa = address;
      const link = await onPromotorPromo(eoa);
      if (link !== '')
        setReferralLink(`https://dev.snowbank.io/${link}`);
      setLoading(false);
    }
    if (address) {
      fetchPromo()
    }
  }, [address]);

  async function handleRegister() {
    setRegisterPendingTx(true);
    try {
      const eoa = address;
      await onRegister(
        eoa,
        customURN
      );
      const link = await onPromotorPromo(address)
      if (link !== '')
        setReferralLink(`https://dev.snowbank.io/${link}`);
      setRegisterPendingTx(false);
    } catch (e) {
      if (didUserReject(e)) {
        notify("error", "User Rejected Transaction");
      } else {
        notify("error", e.reason);
      }
      setRegisterPendingTx(false); // 21600
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    setClipboard(true);
  };

  return (
    <div className='flex-1 p-8 rounded-md snow_effect'>
      <div className="max-w-xl mx-auto">
        {!referralLink && (
          <div className="mb-6">
            <h3 className="text-lg mb-2 text-center text-gray-400">Register as a Referrer</h3>
            {address
              ? <>
                  {isLoading
                    ? <Loading />
                    : <>
                      <div className="refer_custom_input bg-primary/20 my-3 w-full">
                        <div className="refer_input flex items-start flex-col relative w-full">
                          <input className='font-extralight text-xl text-gray-400' value={address} disabled />
                        </div>
                      </div>
                      <div className="refer_custom_input bg-primary/20 my-3 w-full">
                        <div className="refer_input flex items-start flex-col relative w-full">
                          <input type="text" className="font-extralight text-xl text-gray-400" placeholder='Enter Custom URL' value={customURN} name='customURN'
                            onChange={handleChange} />
                        </div>
                      </div>
                      <button
                        onClick={handleRegister}
                        className={`snow_effect mx-2 rounded px-10 py-2 float-right ${isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-green-500 hover:bg-green-600"
                          } text-white`}
                        disabled={isButtonDisabled}
                      >
                        {pendingRegisterTx ? <Loading title='Pending' /> : 'Register'}
                      </button>
                    </>
                  }
                </>
              : <p className='w-full text-3xl text-center my-7'>Please Connect Wallet!</p>
            }
          </div>)}
        {referralLink && (
          <div className="mb-6">
            <h3 className="text-lg mb-2 text-center text-gray-400">Your Referral Link</h3>
            <div className='flex flex-col sm:flex-row gap-3 justify-between my-3'>
              <div className="flex items-center gap-4">
                <div className="refer_custom_input bg-primary/20 w-full">
                  <div className="refer_input flex items-start flex-col relative w-full">
                    <input type="text" className="text-lg font-extralight text-gray-500 text-[start] flex items-center bg-red" value={referralLink} readOnly />
                  </div>

                  <button
                    onClick={copyToClipboard}
                    className="ps-2 py-2 text-white rounded"
                  >
                    {clipboard
                      ?
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="20px" height="20px" viewBox="0 0 60.000000 60.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)" fill="#859AB5" stroke="none">
                        <path d="M414 417 c-71 -73 -147 -156 -169 -184 -22 -29 -44 -52 -50 -53 -5 0 -47 22 -92 50 -46 27 -83 47 -83 44 0 -2 24 -37 54 -77 30 -40 70 -98 89 -129 l34 -57 33 70 c48 102 131 230 218 338 144 175 137 175 -34 -2z"/>
                        </g>
                      </svg>
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="20px" height="20px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#859AB5" stroke="none">
                          <path d="M2215 4548 c-75 -16 -154 -106 -155 -175 l0 -23 -295 0 c-240 0 -301 -3 -324 -15 -31 -15 -61 -69 -61 -108 0 -23 -4 -25 -71 -31 -105 -9 -154 -23 -249 -69 -141 -69 -252 -193 -313 -354 l-22 -58 -3 -1384 c-3 -1536 -7 -1434 65 -1580 52 -108 160 -214 273 -270 152 -74 108 -72 1238 -69 l1007 3 70 25 c150 52 279 169 350 315 64 130 65 150 65 889 l0 664 426 421 c401 396 426 423 411 439 -16 17 -14 21 28 62 l45 44 -67 68 c-37 37 -70 68 -74 68 -3 0 -27 -19 -53 -41 -43 -38 -48 -40 -64 -26 -16 14 -43 -10 -332 -293 l-315 -308 -5 476 -6 477 -27 81 c-48 143 -141 259 -269 337 -82 49 -171 76 -280 84 l-88 6 0 44 c0 36 -6 50 -29 74 l-29 29 -296 0 -296 0 -6 28 c-17 77 -48 124 -102 151 -48 23 -97 30 -147 19z m119 -127 c87 -91 -37 -226 -133 -145 -75 63 -34 173 65 174 32 0 46 -6 68 -29z m-944 -438 c0 -5 -11 -49 -24 -100 -27 -106 -25 -134 16 -168 l30 -25 855 0 855 0 29 29 c35 36 37 65 9 171 -28 108 -31 103 53 98 140 -7 268 -96 331 -228 l31 -65 3 -585 3 -585 -257 -263 -256 -262 -664 0 -664 0 0 -50 0 -50 605 0 c333 0 605 -3 605 -7 0 -4 -23 -31 -52 -60 -45 -45 -64 -80 -139 -245 -49 -106 -86 -194 -84 -197 3 -2 90 35 194 84 l189 87 261 262 261 262 -2 -585 -3 -586 -32 -67 c-60 -127 -173 -210 -311 -229 -43 -6 -441 -8 -1019 -6 -860 3 -953 4 -997 20 -148 51 -258 176 -285 322 -15 78 -15 2619 -1 2696 28 152 141 276 295 325 44 14 165 19 165 7z m3095 -783 l70 -70 -43 -42 -42 -43 -72 72 -72 73 39 40 c21 22 42 40 45 40 3 0 37 -32 75 -70z m-1560 -1570 c32 -33 57 -61 54 -63 -2 -2 -35 -17 -74 -35 l-69 -31 -23 21 c-13 12 -23 26 -23 32 0 18 59 136 67 136 5 0 35 -27 68 -60z" />
                          <path d="M1379 3042 c-26 -24 -32 -36 -32 -72 0 -36 6 -48 32 -72 43 -39 92 -38 132 1 62 62 20 171 -65 171 -25 0 -45 -8 -67 -28z" />
                          <path d="M1740 2970 l0 -50 715 0 715 0 0 50 0 50 -715 0 -715 0 0 -50z" />
                          <path d="M1400 2547 c-33 -16 -63 -77 -54 -111 18 -73 104 -100 163 -50 25 21 31 33 31 66 0 50 -13 74 -50 93 -35 18 -57 19 -90 2z" />
                          <path d="M1740 2460 l0 -50 715 0 715 0 0 50 0 50 -715 0 -715 0 0 -50z" />
                          <path d="M1402 2035 c-94 -41 -65 -184 38 -187 109 -3 143 140 45 187 -39 18 -41 18 -83 0z" />
                          <path d="M1383 1512 c-86 -66 -11 -206 92 -172 105 35 82 190 -29 190 -23 0 -51 -8 -63 -18z" />
                          <path d="M1740 1430 l0 -50 410 0 410 0 0 50 0 50 -410 0 -410 0 0 -50z" />
                        </g>
                      </svg>
                    }
                  </button>
                </div>
              </div>

              <div className='flex items-center gap-3 justify-end'>
                <button className='w-[48px] h-[48px] bg-primary/20 flex items-center justify-center rounded-lg'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M13.2754 10.2747L8.29187 4H3L10.3671 13.2064M13.2754 10.2747L21 20H15.8029L10.3671 13.2064M13.2754 10.2747L19.5019 4M10.3671 13.2064L3.62628 20" stroke="#859AB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <button className='w-[48px] h-[48px] bg-primary/20 flex items-center justify-center rounded-lg'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.6547 20V11.9991H15.7256L16 9.2419H13.6547L13.6582 7.86191C13.6582 7.1428 13.7223 6.75748 14.6907 6.75748H15.9853V4H13.9142C11.4264 4 10.5507 5.33752 10.5507 7.58682V9.24221H9V11.9994H10.5507V20H13.6547Z" stroke="#859AB5" strokeWidth="1.5" />
                  </svg>
                </button>
                <button className='w-[48px] h-[48px] bg-primary/20 flex items-center justify-center rounded-lg'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21 4L3 10.6667L8.14286 13.3333L15.8571 8L10.7143 14.6667L18.4286 20L21 4Z" stroke="#859AB5" strokeWidth="1.5" strokeLinejoin="round" />
                  </svg>
                </button>
                <button className='w-[48px] h-[48px] bg-primary/20 flex items-center justify-center rounded-lg'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#859AB5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#859AB5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
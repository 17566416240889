import React from "react";
import { ToastContainer } from "react-toastify";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { pulsechain, base, avalanche, bsc } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { RefreshContextProvider } from "context/RefreshContext";
import ContractContextProvideer from "context/contracts";
import { ThemeContextProvider } from "context/ThemeContext";
import { LanguageProvider } from "context/Localization";
import { ModalProvider } from "uikit";
import { ALCHEMY_ID } from "config";
import store from "state";

export const sonic = {
  id: 146,
  name: 'Sonic',
  iconUrl: 'https://www.soniclabs.com/apple-icon.png?0afb6d97a9fd9393',
  network: 'sonic',
  nativeCurrency: {
    decimals: 18,
    name: 'sonic',
    symbol: 'S',
  },
  rpcUrls: {
    public: { http: ['https://sonic.drpc.org'] },
    default: { http: ['https://sonic.drpc.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'SnowTrace', url: 'https://snowtrace.io' },
    default: { name: 'SnowTrace', url: 'https://snowtrace.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
}

const Providers = ({ children }) => {
  const { chains, publicClient } = configureChains(
    [pulsechain, base, sonic, avalanche, bsc],
    [alchemyProvider({ apiKey: ALCHEMY_ID }), publicProvider()]
  );

  const { connectors } = getDefaultWallets({
    appName: "snowbase.io",
    projectId: "85ea32d265dfc865d0672c8b6b5c53d2",
    chains,
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <Provider store={store}>
          <HelmetProvider>
            <ThemeContextProvider>
              <LanguageProvider>
                <RefreshContextProvider>
                  <ContractContextProvideer>
                    <ModalProvider>{children}</ModalProvider>
                  </ContractContextProvideer>
                </RefreshContextProvider>
              </LanguageProvider>
            </ThemeContextProvider>
          </HelmetProvider>
          <ToastContainer />
        </Provider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default Providers;
